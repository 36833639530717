<template>
  <div class="vld-parent cyb-login">
    <login-form @sendSubmit="handleSubmit" formName="login">
      <template #form>
        <Card class="p-fluid">
          <template #header>
            <h1 class="active">{{ $t('login.login_title') }}</h1>
            <i
              >({{ $t('login.dont_have_an_account') }}
              <router-link to="./Register" tag="a">{{ $t('register.title') }}</router-link
              >)</i
            >
          </template>
          <template #content>
            <input
              type="text"
              id="username"
              name="username"
              v-model="email"
              autocomplete="username"
              class="hidden"
            />
            <div class="p-fluid p-grid">
              <div class="p-col">
                <div class="p-field">
                  <InputText
                    id="accountid"
                    v-model="accountId"
                    type="number"
                    :placeholder="$t('common.account_id')"
                    autocomplete="account-id"
                  />
                  <field-error :value="v$.accountId" />
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col">
                <div class="p-field">
                  <InputText
                    id="email"
                    v-model="email"
                    type="mail"
                    :placeholder="$t('common.email')"
                    autocomplete="email"
                  />
                  <field-error :value="v$.email" />
                </div>
              </div>
            </div>
            <div class="p-fluid p-grid">
              <div class="p-col">
                <div class="p-field">
                  <Password
                    id="password"
                    v-model="password"
                    toggleMask
                    :feedback="false"
                    :placeholder="$t('common.password')"
                    autocomplete="current-password"
                  />
                  <field-error :value="v$.password" />
                </div>
                <div class="p-field-checkbox">
                  <Checkbox id="chkRememberMe" v-model="rememberMe" :binary="true"></Checkbox
                  ><label for="chkRememberMe">{{ $t('login.rememberMe') }}</label>
                </div>
                <router-link to="/forgotPassword">{{
                  $t('login.forgot_your_password')
                }}</router-link>
              </div>
            </div>
          </template>
          <template #footer>
            <field-error
              class="form-errmsg p-mb-2 text-center"
              id="msgerror"
              :value="errorMessage"
            />
            <Button
              type="submit"
              :label="$t('login.login_button')"
              :disabled="loginState.locked || inProcess"
            />
          </template>
        </Card>
      </template>
    </login-form>
  </div>
</template>

<script>
import { authService } from '../services/auth.service';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { ErrorHelper } from '../helpers/ErrorHelper';
import LoginForm from '../components/LoginForm.vue';
import FieldError from '../components/FieldError.vue';


let defaultUserLogin = {
  rememberMe: false,
  accountId: '',
  email: '',
};

export default {
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      errorMessage: '',
      password: '',
      inProcess: false,
      loginState: {
        count: 0,
        seconds: 0,
        locked: false,
      },
      userLogin: JSON.parse(JSON.stringify(defaultUserLogin)),
    };
  },
  computed: {
    accountId: {
      get: function() {
        return this.userLogin.accountId;
      },
      set: function(value) {
        this.userLogin.accountId = value;
      },
    },
    email: {
      get: function() {
        return this.userLogin.email;
      },
      set: function(value) {
        this.userLogin.email = value;
      },
    },
    rememberMe: {
      get: function() {
        return this.userLogin.rememberMe;
      },
      set: function(checked) {
        this.userLogin.rememberMe = checked;
      },
    },
  },
  validations() {
    return {
      accountId: {},
      email: { required, email },
      password: { required },
    };
  },
  components: {
    LoginForm,
    FieldError,
  },
  mounted() {
    let ls = JSON.parse(localStorage.getItem('thinfinity.workspaces.loginState'));
    if (ls === null) {
      (this.loginState.locked = false), (this.loginState.count = 0), (this.loginState.seconds = 60);
    } else {
      (this.loginState.locked = ls.locked),
        (this.loginState.count = ls.count),
        (this.loginState.seconds = ls.seconds);
    }
    if (this.loginState.locked && this.loginState.seconds <= 0) this.loginState.seconds = 60;
    if (this.loginState.locked) this.initCountdown();
    ls = localStorage.getItem('thinfinity.workspaces.userLogin');
    if (ls != null) {
      this.userLogin = JSON.parse(Buffer.from(ls, 'base64'));
    }
  },
  methods: {
    unLocked() {
      this.loginState.locked = false;
      this.loginState.seconds = 60;
      this.loginState.count = 0;
      this.saveStorage();
      this.errorMessage = '';
    },
    saveStorage() {
      localStorage.setItem('thinfinity.workspaces.loginState', JSON.stringify(this.loginState));
      localStorage.setItem(
        'thinfinity.workspaces.userLogin',
        Buffer.from(JSON.stringify(this.rememberMe ? this.userLogin : defaultUserLogin)).toString(
          'base64'
        )
      );
    },
    initCountdown() {
      var countdown = setInterval(
        function(_this) {
          _this.loginState.seconds--;
          _this.errorMessage = _this.$t('login.severalErrorsMessage', { sec: _this.loginState.seconds });
          _this.saveStorage();
          if (_this.loginState.seconds <= 0) {
            clearInterval(countdown);
            _this.unLocked();
          }
        },
        1000,
        this
      );
    },
    loginLocked() {
      this.loginState.count += 1;
      this.saveStorage();
      if (this.loginState.count > 2) {
        this.loginState.locked = true;
        this.saveStorage();
        this.initCountdown();
        return true;
      }
      return false;
    },
    handleSubmit() {
      this.errorMessage = '';
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.loginLocked();
        return;
      }
      this.inProcess = true;
      this.$store.commit('setCanShowTrialMessage', true);
      authService
        .login(this.accountId, this.email, this.password)
        .then(() => {
          this.inProcess = false;
          this.unLocked();
        })
        .catch((error) => {
          this.inProcess = false;
          this.errorMessage = ErrorHelper.getErrorMessage(error);
          return;
        });
    },
  },
};
</script>
